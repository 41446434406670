import { Booking } from '@vakantiesnl/types';
import { dummyIncludedBookingCosts, dummyEmptyDiscountCodes } from '@vakantiesnl/types/src/booking';
import { BookingCosts, RawBookingCosts, RawSurcharges, Surcharges } from '@vakantiesnl/types/src/booking/booking-costs';
import { Coupon, RawCoupon } from '@vakantiesnl/types/src/booking/coupon';
import dayjs from 'dayjs';

const mapAirline = (raw: Booking.RawAirline): Booking.Airline => ({
	code: raw.code,
	value: raw.value,
});

const mapAirport = (raw: Booking.RawAirport): Booking.Airport => ({
	code: raw.code,
	value: raw.value,
});

const mapCarryOnLuggage = (raw: Booking.RawFlightLuggage): Booking.CarryOnLuggage | undefined => {
	if (!raw.carry_on_width || !raw.carry_on_height || !raw.carry_on_depth) return;

	return {
		width: raw.carry_on_width,
		height: raw.carry_on_height,
		depth: raw.carry_on_depth,
	};
};

export const mapFlightLuggage = (raw: Booking.RawFlightLuggage): Booking.FlightLuggage => ({
	weightAdult: raw.weight_adult,
	weightChild: raw.weight_child,
	weightBaby: raw.weight_baby,
});

const mapFlightSegment = (raw: Booking.RawFlightSegment): Booking.FlightSegment => ({
	airline: raw.airline && mapAirline(raw.airline),
	arrivalAirport: mapAirport(raw.arrival_airport),
	departureAirport: mapAirport(raw.departure_airport),
	departureDateTime: dayjs(raw.departure_date_time).toDate(),
	arrivalDateTime: dayjs(raw.arrival_date_time).toDate(),
	luggage: mapFlightLuggage(raw.luggage),
	carryOnLuggage: mapCarryOnLuggage(raw.luggage),
	displayFlightNumber: raw.display_flight_number,
	duration: undefined,
});

const getLightestLuggage = (flightSegments: Booking.FlightSegment[]): Booking.FlightLuggage | undefined => {
	const lightestLuggage: Booking.FlightLuggage = {
		weightAdult: Number.MAX_VALUE,
		weightChild: Number.MAX_VALUE,
		weightBaby: Number.MAX_VALUE,
	};

	flightSegments.forEach(({ luggage: { weightAdult, weightChild, weightBaby } }) => {
		lightestLuggage.weightAdult = Math.min(lightestLuggage.weightAdult, weightAdult);
		lightestLuggage.weightChild = Math.min(lightestLuggage.weightChild, weightChild);
		lightestLuggage.weightBaby = Math.min(lightestLuggage.weightBaby, weightBaby);
	});

	return Object.values(lightestLuggage).every((item) => item === 0) ? undefined : lightestLuggage;
};

const getSmallestCarryOnLuggage = (flightSegments: Booking.FlightSegment[]): Booking.CarryOnLuggage | undefined => {
	const smallestLuggage: Booking.CarryOnLuggage = {
		width: Number.MAX_VALUE,
		height: Number.MAX_VALUE,
		depth: Number.MAX_VALUE,
	};

	flightSegments.forEach(({ carryOnLuggage }) => {
		smallestLuggage.width = Math.min(smallestLuggage.width, carryOnLuggage?.width ?? 0);
		smallestLuggage.height = Math.min(smallestLuggage.height, carryOnLuggage?.height ?? 0);
		smallestLuggage.depth = Math.min(smallestLuggage.depth, carryOnLuggage?.depth ?? 0);
	});

	return Object.values(smallestLuggage).every((item) => item === 0) ? undefined : smallestLuggage;
};

const mapFlight = (raw: Booking.RawFlight): Booking.Flight => {
	const flightSegments = raw.segments.map(mapFlightSegment);
	const lastFlightSegment = flightSegments[flightSegments.length - 1];

	return {
		flightSegments,
		stopOver: flightSegments.length - 1,
		airline: flightSegments[0].airline,
		departureAirport: flightSegments[0].departureAirport,
		departureDateTime: dayjs(flightSegments[0].departureDateTime).toDate(),
		arrivalAirport: lastFlightSegment.arrivalAirport,
		arrivalDateTime: dayjs(lastFlightSegment.arrivalDateTime).toDate(),
		lightestLuggage: getLightestLuggage(flightSegments),
		carryOnLuggage: getSmallestCarryOnLuggage(flightSegments),
		displayFlightNumber: flightSegments[0].displayFlightNumber,
		duration: undefined,
	};
};

const mapFlights = (raw: Booking.RawFlights): Booking.Flights => ({
	outbound: mapFlight(raw.outbound_flight),
	inbound: mapFlight(raw.inbound_flight),
});

const mapRoom = (raw: Booking.RawRoom): Booking.Room => ({
	board: {
		description: raw.board.description,
	},
	description: raw.description,
	code: raw.code,
	toCode: raw.to_code,
	refIds: raw.ref_ids.map((id) => +id),
});

const mapAccommodation = (raw: Booking.RawAccommodation): Booking.Accommodation => ({
	rooms: raw.rooms?.map(mapRoom),
	giataId: raw.giata_id,
	name: raw.name,
	startDate: raw.start_date,
	endDate: raw.end_date,
});

const mapBookingFlashDeal = (raw: Booking.RawPackage): Booking.BookingFlashDeal | null => {
	if (
		!!raw.discount_amount_type &&
		!!raw.discount_amount &&
		!!raw.expiration_date &&
		!!raw.original_price_per_person &&
		!!raw.original_price_package &&
		!!raw.original_price_per_adult &&
		!!raw.total_discount_applied
	) {
		return {
			discountAmountType: raw.discount_amount_type,
			discountAmount: raw.discount_amount,
			expirationDate: raw.expiration_date,
			originalPricePerPerson: raw.original_price_per_person,
			originalPricePackage: raw.original_price_package,
			originalPricePerAdult: raw.original_price_per_adult,
			originalPricePerChild: raw.original_price_per_child,
			originalPricePerBaby: raw.original_price_per_baby,
			totalDiscountApplied: raw.total_discount_applied,
		};
	}
	return null;
};

const mapPackage = (raw: Booking.RawPackage): Booking.Package => ({
	accommodation: mapAccommodation(raw.accommodation),
	flights: raw.flights ? mapFlights(raw.flights) : null,
	hasTransfer: raw.has_transfer,
	price: raw.price,
	pricePerAdult: raw.price_per_adult,
	pricePerChild: raw.price_per_child,
	pricePerBaby: raw.price_per_baby,
	durationDays: raw.duration_days,
	durationNights: raw.duration_nights,
	tourOperator: raw.tour_operator,
	tourOperatorRemarks: raw.tour_operator_remarks ? mapTourOperatorRemarks(raw.tour_operator_remarks) : null,
	bookingStatus: raw.booking_status ? mapStatus(raw.booking_status) : null,
	displayPrice: raw.display_price,
	flashDeal: mapBookingFlashDeal(raw),
	startDate: raw.start_date,
});

const mapTourOperatorRemarks = (raw: Booking.RawTourOperatorRemark[]): Booking.TourOperatorRemark[] =>
	raw.map((rawItem) => ({
		refId: rawItem.ref_id,
		value: rawItem.value,
	}));

const mapPersons = (raw: Booking.RawPersons): Booking.Persons => {
	const items = raw.items;
	return {
		items: items.map((rawItem) => {
			if (rawItem.is_main_booker) {
				return {
					refId: rawItem.ref_id,
					foundInDb: rawItem.found_in_db,
					dateOfBirth: rawItem.date_of_birth ? rawItem.date_of_birth.slice(0, 10) : null,
					emailAddress: rawItem.email_address,
					firstName: rawItem.first_name,
					gender: rawItem.gender ? rawItem.gender : null,
					isMainBooker: true,
					ageType: rawItem.age_type,
					lastName: rawItem.last_name,
					phone: rawItem.phone,
					marketingOptIn: rawItem.marketing_opt_in,
					age: rawItem.age,
				};
			}

			return {
				refId: rawItem.ref_id,
				foundInDb: rawItem.found_in_db,
				dateOfBirth: rawItem.date_of_birth ? rawItem.date_of_birth.slice(0, 10) : null,
				firstName: rawItem.first_name,
				gender: rawItem.gender ? rawItem.gender : null,
				isMainBooker: false,
				lastName: rawItem.last_name,
				ageType: rawItem.age_type,
				age: rawItem.age,
			};
		}),
	} as Booking.Persons;
};

const mapPatternData = (item: Booking.RawPatternInsuranceItem): Booking.PatternInsuranceItem => ({
	proposalType: item.proposal_type,
	mustAccept: item.must_accept,
	proposalId: item.proposal_id,
	price: {
		consumerPrice: {
			currency: item.price.consumer_price.currency,
			amount: item.price.consumer_price.amount,
		},
		servicePrice: {
			currency: item.price.service_price.currency,
			amount: item.price.service_price.amount,
		},
		insurancePrice: {
			currency: item.price.insurance_price.currency,
			amount: item.price.insurance_price.amount,
		},
		netPrice: {
			currency: item.price.net_price.currency,
			amount: item.price.net_price.amount,
		},
		taxes: item.price.taxes.map((taxItem) => ({
			taxName: taxItem.tax_name,
			taxRate: taxItem.tax_rate,
			taxAmount: taxItem.tax_amount,
		})),
	},
	productName: item.product_name,
	productType: item.product_type,
	documentation: {
		wordingUrl: item.documentation.wording_url,
		ipidUrl: item.documentation.ipid_url,
		scheduleUrl: item.documentation.schedule_url,
		termsAndConditionsUrl: item.documentation.terms_and_conditions_url,
	},
	readMoreUrl: item.read_more_url,
	proposalCommunications: {
		html: item.proposal_communications.html,
		communications: item.proposal_communications.communications,
		additionalInfo: item.proposal_communications.additional_info,
	},
});

const mapInsurance = (item: Booking.RawInsurance): Booking.Insurance => ({
	serviceCode: item.service_code,
	requirementCode: item.requirement_code,
	serviceTypeCode: item.service_type_code,
	name: item.name,
	price: item.price,
	taxes: item.taxes,
	priceTotal: item.price_total,
	type: item.type,
	patternProposalId: item.pattern_proposal_id,
	patternProductId: item.pattern_product_id,
	proposalDetails: mapPatternData(item.proposal_details),
});

const mapInsuranceExtras = (raw: Booking.RawExtras['insurances']): Booking.Extras['insurances'] => {
	const items = raw.items.map(mapInsurance);
	return {
		items,
		pricePolicy: raw.price_policy,
		priceTotal: raw.price_total,
	};
};

const mapLuggage = ({ price, quantity, weight, ...rest }: Booking.RawLuggage): Booking.Luggage => ({
	bookingCode: rest.booking_code,
	price,
	priceTotal: rest.price_total,
	quantity,
	weight,
	refIds: rest.ref_ids ?? [],
});

const mapLuggageExtras = (raw: Booking.RawExtras['luggage']): Booking.Extras['luggage'] => {
	const items = raw.items.map(mapLuggage);

	return {
		items,
		priceTotal: raw.price_total,
	};
};

const mapTransfer = (raw: Booking.RawTransfer): Booking.Transfer => ({
	transferSupplierCode: raw.transfer_supplier_code,
	productTypeCode: raw.product_type_code,
	productTypeName: raw.product_type_name,
	transferDuration: raw.transfer_duration,
	priceType: raw.price_type,
	price: raw.price,
	priceTotal: raw.price_total,
	currency: raw.currency,
	productId: raw.product_id,
});

const mapTransferExtras = (raw: Booking.RawExtras['transfers']): Booking.Extras['transfers'] => {
	const items = raw.items.map(mapTransfer);
	return {
		items,
		priceTotal: raw.price_total,
	};
};

const mapFunds = (raw: Booking.RawFundsItem): Booking.FundsItem => ({
	price: raw.price,
	priceTotal: raw.price_total,
	refCount: raw.ref_count,
	type: raw.type,
});

export const mapFundsExtras = (raw: Booking.RawExtras['funds']): Booking.Funds => {
	const items = raw.items.map(mapFunds);
	return {
		items,
		priceTotal: raw.price_total,
	};
};

export const mapExtras = (raw: Booking.RawExtras): Booking.Extras => ({
	insurances: mapInsuranceExtras(raw.insurances),
	luggage: mapLuggageExtras(raw.luggage),
	transfers: mapTransferExtras(raw.transfers),
	funds: mapFundsExtras(raw.funds),
	discounts: raw.discounts ? mapDiscounts(raw.discounts) : dummyEmptyDiscountCodes,
	surcharges: raw.surcharges ? mapBookingCostsExtras(raw.surcharges) : dummyIncludedBookingCosts,
	additionalCosts: raw.additional_costs ? mapBookingAdditionalCosts(raw.additional_costs, true) : null,
});

export const mapSession = (raw: Booking.RawSession): Booking.Session => ({
	isDirty: raw.is_dirty,
	isExpired: raw.is_expired,
	isCompleted: raw.is_completed,
	davinci: mapDavinci(raw.davinci),
	brand: raw.brand,
	uuid: raw.uuid,
});

const mapDavinci = (raw: Booking.DavinciRaw): Booking.Davinci => ({
	bookingId: raw.booking_id,
	bookingStatus: raw.booking_status && mapStatus(raw.booking_status),
});

export const mapStatus = (raw: Booking.RawStatus): Booking.Status => {
	switch (raw) {
		case Booking.RawStatus.BOOKED:
			return Booking.Status.BOOKED;
		case Booking.RawStatus.NOT_BOOKED:
			return Booking.Status.NOT_BOOKED;
		case Booking.RawStatus.OPEN:
			return Booking.Status.OPEN;
		case Booking.RawStatus.PENDING:
			return Booking.Status.PENDING;
		case Booking.RawStatus.UNKNOWN:
			return Booking.Status.UNKNOWN;
	}
};

export const mapPayments = (raw: Booking.RawPayments): Booking.Payments => {
	const paidMethod = raw.items.find((item) => item.status === Booking.PaymentStatus.PAID)?.method;
	const selectedPaymentMethod = paidMethod ? paidMethod : raw.items[0]?.method;

	return {
		isPaid: raw.is_paid,
		selectedPaymentMethod,
	};
};

export const mapDiscounts = (raw: Booking.RawExtras['discounts']): Booking.Extras['discounts'] => ({
	items: raw.items.map(mapCoupon),
	priceTotal: raw.price_total,
	refId: raw.ref_id,
});

export const mapCoupon = (raw: RawCoupon): Coupon => ({
	couponcode: raw.couponcode,
	quantity: raw.quantity,
	price: raw.price,
	priceTotal: raw.price_total,
	description: raw.description,
	refIds: raw.ref_ids,
});

export const mapBookingCosts = (raw: RawBookingCosts): BookingCosts => ({
	type: raw.type,
	price: raw.price,
	priceTotal: raw.price_total,
	refIds: raw.ref_ids,
	refCount: raw.ref_count,
	bookingStatus: raw.booking_status && mapStatus(raw.booking_status),
});

export const mapBookingCostsExtras = (raw: RawSurcharges): Surcharges => ({
	items: raw.items.map(mapBookingCosts),
	priceTotal: raw.price_total,
	refId: raw.ref_id,
});

export const mapAdditionalCosts = (raw: Booking.RawAdditionalCostItem): Booking.AdditionalCostItem => ({
	id: String(raw.id),
	payAtLocation: raw.pay_at_location,
	mandatory: raw.mandatory,
	description: raw.description,
	price: raw.price,
	priceTotal: raw.price_total,
	refCount: raw.ref_count,
	unitTag: raw.unit_tag,
	/** selected is only returned in the IBE */
	selected: raw.selected,
	/** maxQuantity is only returned in the IBE */
	maxQuantity: raw.max_quantity,
	/** minQuantity is only returned in the IBE */
	minQuantity: raw.min_quantity,
});

export const mapBookingAdditionalCosts = (
	// todo: remove null after BE change
	raw: Booking.RawAdditionalCost | null,
	isIBEBooking = false,
): Booking.AdditionalCost => {
	const mappedItems = raw?.items.map(mapAdditionalCosts) || [];

	/** Only filter on selected items to show. An optional item becomes selected when a PATCH is successful */
	const mandatoryItems = mappedItems.filter((item) =>
		isIBEBooking ? item.mandatory && item.selected : item.mandatory,
	);
	const optionalItems = mappedItems.filter((item) => (isIBEBooking ? !item.mandatory : !item.mandatory));

	return {
		mandatory: {
			payWithBooking: mandatoryItems.filter((item) => !item.payAtLocation),
			payAtLocation: mandatoryItems.filter((item) => item.payAtLocation),
		},
		optional: {
			payWithBooking: optionalItems.filter((item) => !item.payAtLocation),
			payAtLocation: optionalItems.filter((item) => item.payAtLocation),
			all: optionalItems || [],
		},
		priceTotal: raw?.price_total || 0,
	};
};

export const mapBooking = (raw: Booking.RawBookingItem): Booking.BookingItem => ({
	package: mapPackage(raw.package),
	extras: mapExtras(raw.extras),
	priceDownPayment: raw.price_down_payment || 0,
	originalDownPayment: raw.original_down_payment || 0,
	persons: mapPersons(raw.persons),
	priceTotal: raw.price_total,
	displayPriceTotal: raw.display_price_total,
	session: mapSession(raw.session),
	voucherDate: new Date(raw.voucher_date),
	paymentDeadline: new Date(raw.payment_deadline),
	payments: mapPayments(raw.payments),
	bookingProvider: raw.booking_provider,
});

export const mapSelectableTransfer = (raw: Booking.RawSelectableTransfer): Booking.SelectableTransfer => ({
	transferSupplierCode: raw.transfer_supplier_code,
	productIdOutbound: raw.product_id_outbound,
	productIdInbound: raw.product_id_inbound,
	productTypeCode: raw.product_type_code,
	productTypeName: raw.product_type_name,
	description: raw.description,
	transferDuration: raw.transfer_duration,
	priceType: raw.price_type,
	price: raw.price,
});

export const mapSelectableLuggage = (props: Booking.RawSelectableLuggage): Booking.SelectableLuggage => ({
	bookingCode: props.booking_code,
	price: props.price,
	ageRule: props.age_rule,
	weight: props.weight,
});

export const mapSelectableInsurance = (option: Booking.RawSelectableInsurance): Booking.SelectableInsurance => ({
	requirementCode: option.requirement_code,
	serviceTypeCode: option.service_type_code,
	serviceCode: option.service_code,
	name: option.name,
	price: option.price,
	pricePolicy: option.policy_cost,
	taxes: option.taxes,
	type: option.type,
	patternProposalId: option.pattern_proposal_id,
	patternProductId: option.pattern_product_id,
	proposalDetails: mapPatternData(option.proposal_details),
});

export const mapBookingStatuses = (raw: Booking.RawStatuses): Booking.Statuses => ({
	isPaid: raw.is_paid,
	isCompleted: raw.is_completed,
	davinciStatus: mapStatus(raw.davinci_status),
	transferStatus: mapStatus(raw.transfer_status),
	packageStatus: mapStatus(raw.package_status),
});
